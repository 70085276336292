<template>
  <b-card>
    <b-row class="d-flex align-items-center">
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group label="Müşteri Ara">
          <b-form-input
            v-model="keyword"
            placeholder="Müşteri Adı, Firma Adı, Telefon No..."
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group label="Başlangıç Tarihi">
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group label="Bitiş Tarihi">
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="search"
        >
          <FeatherIcon icon="SearchIcon" />
          Raporu Görüntüle
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCol, BFormDatepicker, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  name: 'FilterCard',
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BButton,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      keyword: null,
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['loyaltyProgram/getFilterData']
    },
  },
  watch: {
    keyword(val) {
      if (val) {
        this.filterData.or_like = {
          'com_customer.name': val,
          'com_customer.company_name': val,
          'com_customer.phone': val,
        }
      } else {
        this.filterData.or_like = {}
      }
    },
  },
  methods: {
    search() {
      this.filterData.limit = 25
      this.filterData.start = 0
      this.getReport()
    },
  },
}
</script>
