<template>
  <div>
    <filter-card :get-report="getReport" />
    <pre-loading v-if="loading" />
    <template v-if="reportData.length > 0">
      <rates-card />
      <b-card
        no-body
      >
        <b-table
          responsive="sm"
          :fields="fields"
          :items="reportData"
          striped
        >
          <template #cell(name)="data">
            <div v-if="data.item.company_name">
              <div>
                {{ data.item.company_name }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.name }}
              </div>
            </div>
            <div v-else>
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(sales)="data">
            <div
              class="font-weight-bold"
              :class="parseInt(data.item.sales)? 'text-success' : 'text-danger'"
            >
              {{ data.item.sales }}
            </div>
          </template>
          <template #cell(services)="data">
            <div
              class="font-weight-bold"
              :class="parseInt(data.item.services)? 'text-success' : 'text-danger'"
            >
              {{ data.item.services }}
            </div>
          </template>
          <template #cell(realtySales)="data">
            <div
              class="font-weight-bold"
              :class="parseInt(data.item.realtySales)? 'text-success' : 'text-danger'"
            >
              {{ data.item.realtySales }}
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <div class="text-muted text-center font-small-2 mb-1">
            Toplam {{ dataCount | toNumber }} adet kayıt bulundu.
          </div>
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCard, BTable, BCardFooter, BPagination,
} from 'bootstrap-vue'
import FilterCard from '@/views/Reports/Crm/LoyaltyProgram/FilterCard.vue'
import RatesCard from '@/views/Reports/Crm/LoyaltyProgram/RatesCard.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'LoyaltyProgram',
  components: {
    BCard,
    BCardFooter,
    BPagination,
    BTable,
    FilterCard,
    RatesCard,
    PreLoading,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'name',
          label: 'Müşteri',
        },
        {
          key: 'phone',
          label: 'Telefon',
        },
        {
          key: 'sales',
          label: 'Sıfır Satış',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'services',
          label: 'Servis',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'realtySales',
          label: 'GY Satış',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    loading() {
      return this.$store.getters['loyaltyProgram/getLoading']
    },
    reportData() {
      return this.$store.getters['loyaltyProgram/getReport']
    },
    dataCount() {
      return this.$store.getters['loyaltyProgram/getDataCount']
    },
    filterData() {
      return this.$store.getters['loyaltyProgram/getFilterData']
    },
  },
  watch: {
    currentPage(val) {
      this.filterData.start = (val - 1) * 25
      this.getReport()
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('loyaltyProgram/report', this.filterData)
    },
  },
}
</script>
