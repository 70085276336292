<template>
  <b-card>
    <b-row class="d-flex align-items-center">
      <b-col class="text-center">
        <div class="bordered py-1 rounded border">
          <div class="font-weight-light font-medium-2">
            Sıfır Satış
          </div>
          <div class="font-weight-bolder font-medium-4 text-primary">
            %{{ ratesData.sales.rate | toNumber }}
          </div>
          <div class="text-muted font-small-2">
            Toplam {{ ratesData.sales.total | toNumber }} adet kayıt bulundu.
          </div>
        </div>
      </b-col>
      <b-col class="text-center">
        <div class="bordered py-1 rounded border">
          <div class="font-weight-light font-medium-2">
            Servis
          </div>
          <div class="font-weight-bolder font-medium-4 text-primary">
            %{{ ratesData.services.rate | toNumber }}
          </div>
          <div class="text-muted font-small-2">
            Toplam {{ ratesData.services.total | toNumber }} adet kayıt bulundu.
          </div>
        </div>
      </b-col>
      <b-col class="text-center">
        <div class="bordered py-1 rounded border">
          <div class="font-weight-light font-medium-2">
            GY Satış
          </div>
          <div class="font-weight-bolder font-medium-4 text-primary">
            %{{ ratesData.realtySales.rate | toNumber }}
          </div>
          <div class="text-muted font-small-2">
            Toplam {{ ratesData.realtySales.total | toNumber }} adet kayıt bulundu.
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  name: 'FilterCard',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    ratesData() {
      return this.$store.getters['loyaltyProgram/getDataRates']
    },
  },
}
</script>
